define("innowaze-ember2/adapters/application", ["exports", "ember-data", "ember-inject-optional", "fetch", "innowaze-ember2/configuration", "innowaze-ember2/utils/formatUrl", "innowaze-ember2/config/environment"], function (_exports, _emberData, _emberInjectOptional, _fetch, _configuration, _formatUrl, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var i18nDigestCache = "";
  var configDigestHeader = "x-configuration-digest";
  var i18nDigestHeader = "x-translations-digest";

  var _default = _emberData.default.JSONAPIAdapter.extend({
    locale: Ember.inject.service(),
    assetMap: _emberInjectOptional.default.service(),
    host: _configuration.default.apiUrl,
    namespace: _configuration.default.apiNamespace,
    headers: {
      "Content-Type": "application/json"
    },
    // Add the CSRF token if available
    ajax: function ajax(url, method) {
      var hash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      hash.xhrFields = {
        withCredentials: true
      };
      hash.crossDomain = true;

      if (this.session.get("configuration.csrf")) {
        this.headers["X-CSRF-Token"] = this.session.get("configuration.csrf");
      }

      if (_environment.default.enableTokenAuth) {
        // Set Authorization header if token available
        var token = localStorage.getItem("userToken");

        if (token) {
          this.headers["Authorization"] = "Token token=".concat(token);
        }
      }

      this.headers["Accept-Language"] = this.get("locale.currentLocale");
      return this._super(url, method, hash);
    },
    // Add the .json suffix to all URLs
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);

      if (url.match(/\.json/)) {
        return url;
      }

      url = url.split("?");
      url[0] += ".json";
      url = url.join("?");
      return url;
    },
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      return (0, _formatUrl.default)(this._super.apply(this, arguments), snapshot);
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      return (0, _formatUrl.default)(this._super.apply(this, arguments), snapshot);
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      return (0, _formatUrl.default)(this._super.apply(this, arguments), snapshot);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      return (0, _formatUrl.default)(this._super.apply(this, arguments), snapshot);
    },
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      return (0, _formatUrl.default)(this._super.apply(this, arguments), snapshot);
    },
    urlForQuery: function urlForQuery(query, modelName) {
      // Handle challenge_id parameter specifically
      // We want it to be included in the formatted URL, but other parameters must be handled "natively" as query string
      var challenge_id = query.challenge_id;
      delete query.challenge_id;

      var url = this._super.apply(this, arguments);

      if (challenge_id) {
        return (0, _formatUrl.default)(url, {
          adapterOptions: {
            challenge_id: challenge_id
          }
        });
      }

      return url;
    },
    isInvalid: function isInvalid(status, headers, payload) {
      if (status === 403) {
        var div = document.createElement("div");
        var warning = document.createElement("div");
        div.setAttribute("class", "noAccessOverlay");
        warning.innerHTML = "".concat(payload, "<br /><a href='/'>Return to home</a>");
        div.appendChild(warning);
        document.body.appendChild(div);

        var deleteItself = function deleteItself() {
          document.body.removeChild(div);
          window.removeEventListener("hashchange", deleteItself);
        };

        window.addEventListener("hashchange", deleteItself);
      }

      return this._super(status, headers, payload);
    },
    handleResponse: function handleResponse(status, headers, payload) {
      var _this = this;

      // Trigger configuration/translations update if they have changed remotely
      if (this.session.get("configurationDigest") && configDigestHeader in headers && this.session.get("configurationDigest") !== headers[configDigestHeader]) {
        this.store.findRecord("configuration", 1, {
          reload: true
        }).then(function (config) {
          _this.session.set("configuration", config);

          _this.assetMap.loadThemeStyleSheet(config.get("dict.theme"));

          _this.assetMap.loadFavicon(window.instanceImageFolder);
        });
      }

      if (i18nDigestCache && i18nDigestHeader in headers && i18nDigestCache !== headers[i18nDigestHeader]) {
        (0, _fetch.default)(_configuration.default.apiUrl + "/api/translations").then(function (response) {
          return response.json();
        }).then(function (translations) {
          _this.locale.reloadTranslations(translations);
        });
      }

      this.session.set("configurationDigest", headers[configDigestHeader]);
      i18nDigestCache = headers[i18nDigestHeader];

      if (status === 401) {
        if (payload && payload.error) {
          return payload.error;
        }

        return "Unauthorized resource access";
      }

      if (status > 401 && status < 500 && payload.errors) {
        return new _emberData.default.InvalidError(Object.keys(payload.errors).map(function (key) {
          return {
            detail: "".concat(key, ": ").concat(payload.errors[key]),
            source: {
              // See: https://api.emberjs.com/ember-data/2.18/classes/DS.InvalidError
              pointer: "/data/attributes/".concat(key)
            }
          };
        }));
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});